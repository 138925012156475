// Reconnect ActionCable after switching accounts

import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static values = {
    roles: Array,
    url: String
  }

  reconnect(event) {
    if (consumer.connection.isActive()) {
      consumer.connection.reopen()
    }
  }

  connect() {
    if(document.getElementById('account_invitation_role_u')) {
      this.inviteeRole();
    }
  }

  uploadImage(e) {
    e.target.form.requestSubmit();
  }

  submitForm(e) {
    e.target.textContent = 'Saving...';
    e.target.classList.add('opacity-70');
  }

  inviteeRole(e) {
    for(let i=0; i< this.rolesValue.length; i++) {
      let checkbox = document.getElementById(this.rolesValue[i]);
      if(checkbox.checked) {
        // we can invite user with one role. so make sure only one role is checked
        // if role is already selected then un-check all checked roles. and finally check the current role.
        document.getElementById(this.rolesValue[i]).click();
      }
    }

    //on page load select the first role.
    let role = e?.target?.value || document.getElementById('account_invitation_role_u').value;
    document.getElementById(role).click();
  }

  remove(e) {
    e.preventDefault();
    e.stopPropagation();

    if (confirm('Are you sure you want to remove this image? This action cannot be undone and the image cannot be restored.')) {
      fetch(this.urlValue, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content},
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      })
        .then(response => {
          if (response.ok) {
            this.element.closest('.inline-block').remove()
          } else {
            console.error('Failed to remove image:', response.status, response.statusText)
            alert('Failed to remove image')
          }
        })
        .catch(error => {
          console.error('Error:', error)
        })
    }
  }
}
